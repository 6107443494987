import { GlobalContextProvider } from '@/shared/GlobalContext/GlobalContext';
import { SidebarContextProvider } from '@/shared/Page/Sidebar/SidebarContext/SidebarContext';
import { SSOContextProvider } from '@/shared/SSOContext/SSOContext';
import { ThemesContextProvider } from '@/shared/ThemesContext/ThemesContext';
import { StudentContextProvider } from '@/shared/StudentContext/StudentContext';
import Head from 'next/head';
import '../styles/globals.css';
import { NavigationContextProvider } from '@/shared/NavigationContext/NavigationContext';
import ErrorBoundary from '@/shared/ErrorBoundary';
import { ScrollContextProvider } from '@/shared/KeepScroll/ScrollContext/ScrollContext';
import { Result } from 'antd';
import Image from 'next/image';
import { WebsocketsContextProvider } from '@/shared/WebsocketsContext/WebsocketsContext';
import { RealTimeActivityContextProvider } from '@/shared/RealTimeActivityContext/RealTimeActivityContext';
import { AudioAutoPlayerContextProvider } from '@/shared/Players/AudioAutoPlayer/AudioAutoPlayerContext';
import { StudentRealTimeActivityContextProvider } from '@/shared/StudentRealTimeActivityContext/StudentRealTimeActivityContext';
import { StrictMode, useEffect } from 'react';
import { FullscreenContextProvider } from '@/shared/FullscreenContext/FullscreenContext';
import 'react-aspect-ratio/aspect-ratio.css';
import { setupAxiosCache } from '@/api/axiosInstance';
import { GamificationContextProvider } from '@/shared/Gamification/GamificationContext/GamificationContext';
import PlausibleAnalytics from '@/shared/PlausibleAnalytics/PlausibleAnalytics';
import PageSEO from '@/shared/PageSEO/PageSEO';

const maintenanceMode = false;

function MyApp({ Component, ...pageProps }) {
  useEffect(() => {
    // Polyfill for queueMicrotask
    if (typeof window.queueMicrotask !== 'function') {
      window.queueMicrotask = function (callback) {
        Promise.resolve()
          .then(callback)
          .catch((e) =>
            setTimeout(() => {
              throw e;
            })
          );
      };
    }

    setupAxiosCache();
  }, []);

  return (
    <ErrorBoundary>
      <Head>
        <title>Rockalingua</title>
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#00BCEB"
        />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      {maintenanceMode ? (
        <Result
          icon={
            <Image
              src={
                process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST + '/img/logo.png'
              }
              alt="Rockalingua logo"
              width={258}
              height={77}
            />
          }
          title="We are preparing new things..."
          subTitle="Rockalingua will be ready in a bit"
        />
      ) : (
        <GlobalContextProvider referer={pageProps.referer}>
          <FullscreenContextProvider>
            <ThemesContextProvider>
              <SSOContextProvider>
                <SidebarContextProvider>
                  <StudentContextProvider>
                    <NavigationContextProvider>
                      <ScrollContextProvider>
                        <AudioAutoPlayerContextProvider>
                          <WebsocketsContextProvider>
                            <RealTimeActivityContextProvider>
                              <StudentRealTimeActivityContextProvider>
                                <GamificationContextProvider>
                                  <PlausibleAnalytics>
                                    <Component {...pageProps} />
                                  </PlausibleAnalytics>
                                </GamificationContextProvider>
                              </StudentRealTimeActivityContextProvider>
                            </RealTimeActivityContextProvider>
                          </WebsocketsContextProvider>
                        </AudioAutoPlayerContextProvider>
                      </ScrollContextProvider>
                    </NavigationContextProvider>
                  </StudentContextProvider>
                </SidebarContextProvider>
              </SSOContextProvider>
            </ThemesContextProvider>
          </FullscreenContextProvider>
        </GlobalContextProvider>
      )}
    </ErrorBoundary>
  );
}

MyApp.getInitialProps = async ({ ctx }) => {
  return {
    referer: ctx?.req?.headers?.referer,
  };
};

export default MyApp;
