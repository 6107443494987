import Head from 'next/head';
import { useRouter } from 'next/router';

export default function PageSEO({
  title,
  description,
  ogImage,
  ogType = 'website',
  noindex = false,
  customCanonicalUrl,
  includeSchemaMarkup = false,
}) {
  const router = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_SITE_URL || 'https://rockalingua.com';

  const canonicalUrl = `${baseUrl}${customCanonicalUrl || router.asPath}`;

  const ogImageUrl =
    ogImage ||
    `${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_HOST}/production/static/img/small-logo.png`;

  const robotsContent =
    noindex || process.env.NODE_ENV != 'production'
      ? 'noindex, nofollow'
      : 'index, follow';

  return (
    <Head>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}

      <link rel="canonical" href={canonicalUrl} />

      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={ogImageUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImageUrl} />

      <meta name="robots" content={robotsContent} />

      {/* Add Schema Markup JSON-LD only for home and pricing pages */}
      {includeSchemaMarkup && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaMarkup) }}
        />
      )}
    </Head>
  );
}

// Schema markup JSON-LD for home and pricing pages
const schemaMarkup = {
  '@context': 'https://schema.org',
  '@type': 'EducationalOrganization',
  name: 'Rockalingua',
  url: 'https://rockalingua.com',
  logo: 'https://cdn.rockalingua.com/production/static/img/rockaLogo.png',
  description:
    'Rockalingua is an educational platform that offers songs, videos, and games to make learning Spanish fun for kids.',
  sameAs: [
    'https://www.facebook.com/Rockalingua',
    'https://www.instagram.com/Rockalingua',
    'https://www.youtube.com/@Rockalingua',
    'https://x.com/Rockalingua',
  ],
  founder: {
    '@type': 'Person',
    name: 'Founder Name',
  },
  offers: {
    '@type': 'Offer',
    name: 'Subscription Plans',
    url: 'https://rockalingua.com/pricing',
    priceCurrency: 'USD',
    offers: [
      {
        '@type': 'Offer',
        name: 'Teacher Pro',
        priceSpecification: [
          {
            '@type': 'PriceSpecification',
            price: '40.00',
            priceCurrency: 'USD',
            billingPeriod: 'Month',
          },
          {
            '@type': 'PriceSpecification',
            price: '299.00',
            priceCurrency: 'USD',
            billingPeriod: 'Year',
          },
        ],
      },
      {
        '@type': 'Offer',
        name: 'Teacher',
        priceSpecification: [
          {
            '@type': 'PriceSpecification',
            price: '20.00',
            priceCurrency: 'USD',
            billingPeriod: 'Month',
          },
          {
            '@type': 'PriceSpecification',
            price: '199.00',
            priceCurrency: 'USD',
            billingPeriod: 'Year',
          },
        ],
      },
      {
        '@type': 'Offer',
        name: 'Family Plan - 1 to 1 Private Lessons',
        priceSpecification: {
          '@type': 'PriceSpecification',
          price: '199.00',
          priceCurrency: 'USD',
          billingPeriod: 'Month',
        },
      },
      {
        '@type': 'Offer',
        name: 'Family Full Access',
        priceSpecification: [
          {
            '@type': 'PriceSpecification',
            price: '20.00',
            priceCurrency: 'USD',
            billingPeriod: 'Month',
          },
          {
            '@type': 'PriceSpecification',
            price: '99.00',
            priceCurrency: 'USD',
            billingPeriod: 'Year',
          },
        ],
      },
    ],
  },
  educationalProgram: {
    '@type': 'EducationalOccupationalProgram',
    name: 'Learn Spanish with Music',
    educationalProgramMode: 'online',
    provider: {
      '@type': 'EducationalOrganization',
      name: 'Rockalingua',
      url: 'https://rockalingua.com',
    },
  },
};
