export const punctuation = [
  ',',
  '.',
  '¿',
  '?',
  '¡',
  '!',
  ';',
  ':',
  '...',
  '…',
  '-',
  '(',
  ')',
  '"',
  "'",
];

/**
 * Capitalize
 * @param {String} word
 * @returns {String}
 */
export const capitalize = (word) => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const capitalizeAllWords = (sentence) =>
  sentence
    .split(/\s+/)
    .map((word) => capitalize(word))
    .join(' ');

/**
 * Concatenate strings in words leaving a space between them, except for words that are punctuation.
 * @param {*} words - array of words
 * @returns {String}
 */
export const phraseFromStrings = (words) =>
  words
    .map((w, index) => (punctuation.includes(words[index + 1]) ? w : w + ' '))
    .join('')
    .trim();

export function truncateObjectString(objStr, maxLength = 100) {
  if (objStr.length > maxLength) {
    const truncated = objStr.substring(0, maxLength);
    const truncIndex = Math.max(
      truncated.lastIndexOf(','),
      truncated.lastIndexOf('}'),
      truncated.lastIndexOf(']'),
      truncated.lastIndexOf(' ')
    );
    const truncData =
      (truncIndex > 0 ? objStr.substring(0, truncIndex + 1) : truncated) +
      ' ...}';
    return truncData;
  }
  return objStr;
}

export function cleanAndTruncateText(html, length = 150) {
  if (!html) return '';

  const htmlWithoutImages = html.replace(/<img[^>]*>/g, '');

  const cleanText = htmlWithoutImages.replace(/<[^>]*>/g, '');

  const decodedText = cleanText
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&nbsp;/g, ' ')
    .replace(/&ndash;/g, '-')
    .replace(/&mdash;/g, '-')
    .replace(/&aacute;/g, 'á')
    .replace(/&eacute;/g, 'é')
    .replace(/&iacute;/g, 'í')
    .replace(/&oacute;/g, 'ó')
    .replace(/&uacute;/g, 'ú')
    .replace(/&ntilde;/g, 'ñ');

  const trimmedText = decodedText.replace(/\s+/g, ' ').trim();

  if (trimmedText.length <= length) {
    return trimmedText;
  }

  // Truncate at the last complete word within the length
  const truncated = trimmedText.substring(0, length);
  const lastSpaceIndex = truncated.lastIndexOf(' ');

  return lastSpaceIndex > 0
    ? truncated.substring(0, lastSpaceIndex) + '...'
    : truncated + '...';
}
